<template lang="pug">
  form#new_contact(@submit.prevent="onSubmit" class="mx-auto px-10 md:max-w-430p")

    .form-group(v-if="isSuccess || Object.keys(errors).length")
      form-error(:errors="errors" v-if="!isSuccess")

    .form-group.form-group--smooth
      input.form-control.input--smooth(type="text" required="required" v-model="contact.lastname" @focus="loadRecaptcha" :class="{'field_with_errors': errors.lastname, 'input--filled': contact.lastname}")
      label.label--smooth {{ translations.lastname }}

    .form-group.form-group--smooth
      input.form-control.input--smooth(type="text" required="required" v-model="contact.firstname" @focus="loadRecaptcha" :class="{'field_with_errors': errors.firstname, 'input--filled': contact.firstname}")
      label.label--smooth {{ translations.firstname }}

    .form-group.form-group--smooth
      input.form-control.input--smooth(type="email" required="required" v-model="contact.email" @focus="loadRecaptcha" :class="{'field_with_errors': errors.email, 'input--filled': contact.email}")
      label.label--smooth {{ translations.email }}

    .form-group.form-group--smooth
      input.form-control.input--smooth(type="text" v-model="contact.company" required="required" @focus="loadRecaptcha" :class="{'field_with_errors': errors.company, 'input--filled': contact.company}")
      label.label--smooth {{ translations.company }}

    p-check(v-model="contact.rgpd_terms_accepted" required="required" @change="loadRecaptcha" class="p-smooth p-default text-gray text-xs p-curve mb-10" color="primary") {{ translations.rgpd_acceptance }}

    .form-group.row.align-items-center.sm-gutter
      .col-sm-6.col-5.sm-gutter
        vue-recaptcha(
          ref="recaptcha"
          size="invisible"
          :sitekey="recaptchaAppKey"
          @verify="onCaptchaVerify"
          @expired="onCaptchaExpired"
        )

    div(class="text-center")
      button.btn.btn-primary(type="submit" :disabled="isSubmitting")
        span {{ translations.submit }}
</template>

<script>

import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import FormError from './FormError'

export default {
  components: {
    VueRecaptcha,
    FormError
  },

  props: {
    translations: {
      type: Object,
      required: true
    },

    recaptchaAppKey: {
      type: String,
      required: true
    },

    redirectOnSubmit: {
      type: String,
      default: ''
    },

    contactable: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      contact: {
        lastname: '',
        firstname: '',
        email: '',
        company: '',
        contactable_type: this.contactable.type,
        contactable_id: this.contactable.id,
        newsletter_subscription: true,
        rgpd_terms_accepted: false,
        provenance: "newsletter_form"
      },
      errors: {},
      isSuccess: false,
      isSubmitting: false,
      isPhoneValid: true
    }
  },

  methods: {
    onSubmit () {
      if (this.isPhoneValid) {
        this.$refs.recaptcha.execute()
      } else {
        this.$refs.phoneInput.focus()
      }
    },

    onCaptchaVerify (recaptchaToken) {
      this.isSubmitting = true
      this.isSuccess = false
      this.$refs.recaptcha.reset()

      this.errors = {}

      axios.post('/api/v1/contacts', {
        contact: this.contact,
        'g-recaptcha-response': recaptchaToken
      })
        .then((response) => {
          if (this.redirectOnSubmit) {
            window.location.href = this.redirectOnSubmit
          } else {
            this.isSuccess = true

            this.resetForm()
          }
        })
        .catch(({ response: { data } }) => {
          this.isSubmitting = false
          this.errors = data.errors.data.attributes.errors
        })
        .then(() => {
          this.isSubmitting = false
        })
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    loadRecaptcha () {
      if (window.__recaptchaLoaded) return
      window.__recaptchaLoaded = true

      const script = document.createElement("script")
      script.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
      document.head.appendChild(script)
    },

    resetForm () {
      this.contact = {
        lastname: '',
        firstname: '',
        email: '',
        company: '',
        contactable_type: this.contactable.type,
        contactable_id: this.contactable.id,
        newsletter_subscription: true,
        rgpd_terms_accepted: false,
        provenance: "newsletter_form"
      }

      this.errors = {}
    }
  }
}
</script>
