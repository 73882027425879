/* eslint no-console:0 */

// Polyfill =====================================================================
import "intersection-observer"

// MODULES =====================================================================
import Rails from 'rails-ujs'
import SmoothScroll from 'smooth-scroll'
import axios from 'axios'

import "@appPages/vue"

import "stylesheets/application.sass"
import CustomFileInputList from "@utils/custom-file-input-list"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import ProductHero from "@utils/product-hero"
import ScrollController from "@utils/scroll_controller"
import AnimateScroll from "@utils/animate-scroll"
import Swiper from "@utils/swiper"
import LinkHrefObfuscation from "@utils/link-href-obfuscation"

// Fonts ===============================================================
import "typeface-poppins"
import "typeface-amatic-sc"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new CustomFileInputList()
  new ProductHero()
  new AnimateScroll()
  new Swiper()
  new ScrollController()
  new LinkHrefObfuscation()

  new SmoothScroll('a[href*="#"]', {
    speed: 450
  })

  const token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  axios.interceptors.response.use(response => {
    return response
  }, error => {
    new FlashMessage("Une erreur s'est produite", "danger")
    return Promise.reject(error)
  })
}, { passive: true })
