<template lang="pug">
  form#new_contact(@submit.prevent="onSubmit" class="mx-auto px-10 md:max-w-430p")

    .form-group(v-if="isSuccess || Object.keys(errors).length")
      form-error(:errors="errors" v-if="!isSuccess")

    .w-full(class="md:flex")
      div(class="md:pr-6 md:w-1/2")
        .form-group.form-group--smooth.mb-0
          input.form-control.input--smooth(type="text" required="required" v-model="contact.lastname" @focus="loadRecaptcha" :class="{'field_with_errors': errors.lastname, 'input--filled': contact.lastname}")
          label.label--smooth {{ translations.lastname }}

      div(class="md:pr-6 md:w-1/2")
        .form-group.form-group--smooth.mb-0
          input.form-control.input--smooth(type="text" required="required" v-model="contact.firstname" @focus="loadRecaptcha" :class="{'field_with_errors': errors.firstname, 'input--filled': contact.firstname}")
          label.label--smooth {{ translations.firstname }}

    .w-full(class="md:flex")
      div(class="md:pr-6 md:w-1/2")
        .form-group.form-group--smooth.mb-0
          input.form-control.input--smooth(type="email" required="required" v-model="contact.email" @focus="loadRecaptcha" :class="{'field_with_errors': errors.email, 'input--filled': contact.email}")
          label.label--smooth {{ translations.email }}

      div(class="md:pr-6 md:w-1/2")
        .form-group.form-group--smooth.mb-0
          input.form-control.input--smooth(type="phone" ref="phoneInput"  v-shb-phone-input="" @input="checkPhoneValidity" v-model="contact.phone" @focus="loadRecaptcha" :class="{'field_with_errors': !isPhoneValid || errors.phone, 'input--filled': contact.phone}")

    .form-group.form-group--smooth
      input.form-control.input--smooth(type="text" v-model="contact.role" required="required" @focus="loadRecaptcha" :class="{'field_with_errors': errors.role, 'input--filled': contact.role}")
      label.label--smooth {{ translations.role }}

    .form-group.form-group--smooth
      input.form-control.input--smooth(type="text" v-model="contact.company" required="required" @focus="loadRecaptcha" :class="{'field_with_errors': errors.company, 'input--filled': contact.company}")
      label.label--smooth {{ translations.company }}

    .form-group.form-group--smooth
      textarea.form-control.input--smooth(v-model="contact.message" required="required" @focus="loadRecaptcha" :class="{'field_with_errors': errors.message, 'input--filled': contact.message}")
      label.label--smooth {{ translations.message }}

    .form-group.form-group--smooth
      p.font-semibold.mb-2 La Solution qui vous intéresse
      p-check(v-for="product in products" :key="product.id" v-model="contact.product_ids" :value="product.id" @change="loadRecaptcha" class="p-smooth p-default p-curve mr-10" color="primary")
        | {{ product.title }}

    p-check(v-model="contact.rgpd_terms_accepted" required="required" @change="loadRecaptcha" class="p-smooth p-default text-gray text-xs p-curve mb-10" color="primary") {{ translations.rgpd_acceptance }}

    .form-group.row.align-items-center.sm-gutter
      .col-sm-6.col-5.sm-gutter
        vue-recaptcha(
          ref="recaptcha"
          size="invisible"
          :sitekey="recaptchaAppKey"
          @verify="onCaptchaVerify"
          @expired="onCaptchaExpired"
        )

    div(class="text-center")
      button.btn.btn-primary(type="submit" :disabled="isSubmitting")
        span {{ translations.submit }}
</template>

<script>

import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import FormError from './FormError'
import intlTelInput from 'intl-tel-input'
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.i18n.js'
import 'intl-tel-input/build/css/intlTelInput.min.css'

export default {
  components: {
    VueRecaptcha,
    FormError
  },

  directives: {
    shbPhoneInput: {
      inserted: (el) => {
        el.intlTelInput = intlTelInput(el, {
          preferredCountries: ['FR'],
          initialCountry: 'FR',
          separateDialCode: false,
          nationalMode: false,
          formatOnDisplay: false,
          autoInsertDialCode: true,
          customContainer: "",
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.3.3/build/js/utils.js"
        })

        el.cleave = new Cleave(el, {
          phone: true,
          phoneRegionCode: el.intlTelInput.getSelectedCountryData().iso2
        })
      },
      update: (el) => {
        const event = new Event('input', { bubbles: true })
        setTimeout(function () {
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
        }, 100)
      }
    }
  },

  props: {
    translations: {
      type: Object,
      required: true
    },

    recaptchaAppKey: {
      type: String,
      required: true
    },

    redirectOnSubmit: {
      type: String,
      default: ''
    },

    contactable: {
      type: Object,
      default () { return {} }
    },

    products: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      contact: {
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        role: '',
        company: '',
        message: '',
        contactable_type: this.contactable.type,
        contactable_id: this.contactable.id,
        product_ids: [],
        newsletter_subscription: true,
        rgpd_terms_accepted: false,
        provenance: "contact_form"
      },
      errors: {},
      isSuccess: false,
      isSubmitting: false,
      isPhoneValid: true
    }
  },

  computed: {
    cleave: function () {
      return this.$refs.phoneInput.cleave
    },
    intlTelInput: function () {
      return this.$refs.phoneInput.intlTelInput
    }
  },

  beforeDestroy: function () {
    this.$refs.phoneInput.removeEventListener("countrychange", this.boundUpdateCountryCode)
    this.$refs.phoneInput.removeEventListener("focus", this.boundSetDialCode)
    this.intlTelInput.destroy()
    this.cleave.destroy()
  },

  mounted: function () {
    this.boundUpdateCountryCode = this.updateCountryCode.bind(this)
    this.$refs.phoneInput.addEventListener("countrychange", this.boundUpdateCountryCode)

    this.boundSetDialCode = this.setDialCode.bind(this)
    this.$refs.phoneInput.addEventListener("focus", this.boundSetDialCode)

    this.boundFormatValue = this.formatValue.bind(this)
    this.$refs.phoneInput.addEventListener("blur", this.boundFormatValue)
  },

  methods: {
    onSubmit () {
      if (this.isPhoneValid) {
        this.$refs.recaptcha.execute()
      } else {
        this.$refs.phoneInput.focus()
      }
    },

    onCaptchaVerify (recaptchaToken) {
      this.isSubmitting = true
      this.isSuccess = false
      this.$refs.recaptcha.reset()

      this.errors = {}

      axios.post('/api/v1/contacts', {
        contact: this.contact,
        'g-recaptcha-response': recaptchaToken,
        redirection_path: this.redirectOnSubmit
      })
        .then((response) => {
          if (this.redirectOnSubmit) {
            window.location.href = this.redirectOnSubmit
          } else {
            this.pushNewContactEvent(response.data)
            this.isSuccess = true
            this.resetForm()
          }
        })
        .catch(({ response: { data } }) => {
          this.isSubmitting = false
          this.errors = data.errors.data.attributes.errors
        })
        .then(() => {
          this.isSubmitting = false
        })
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    checkPhoneValidity () {
      this.isPhoneValid = this.contact.phone ? this.intlTelInput.isValidNumber() : true
    },

    loadRecaptcha () {
      if (window.__recaptchaLoaded) return
      window.__recaptchaLoaded = true

      const script = document.createElement("script")
      script.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
      document.head.appendChild(script)
    },

    resetForm () {
      this.contact = {
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        role: '',
        company: '',
        message: '',
        contactable_type: this.contactable.type,
        contactable_id: this.contactable.id,
        product_ids: [],
        newsletter_subscription: true,
        rgpd_terms_accepted: false,
        provenance: "contact_form"
      }

      this.errors = {}
    },

    updateCountryCode () {
      this.cleave.setPhoneRegionCode = this.intlTelInput.getSelectedCountryData().iso2
      this.cleave.setRawValue(this.cleave.getRawValue())
    },

    setDialCode () {
      if (!this.cleave.getRawValue()) this.cleave.setRawValue("+" + this.intlTelInput.selectedCountryData.dialCode)
    },

    formatValue () {
      if (this.cleave.getRawValue() && this.cleave.getRawValue() === this.cleave.getFormattedValue()) {
        const value = this.cleave.getRawValue().startsWith('+') ? this.cleave.getRawValue() : `+${this.cleave.getRawValue()}`
        this.cleave.setRawValue(value)
      }
    },

    pushNewContactEvent (data) {
      window.uetq = window.uetq || []
      window.uetq.push('set', {
        pid: {
          em: data.data.attributes.email,
          ph: data.data.attributes.phone
        }
      })
    }
  }
}
</script>
