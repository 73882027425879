import { throttle } from 'lodash-es'

export default class {
  constructor () {
    this.element = document.querySelectorAll('.scroll-element')

    // #### bind events
    this.bindEvents()
  }

  bindEvents () {
    window.addEventListener('scroll', throttle(this.handleScroll.bind(this), 15), { passive: true })
  }

  handleScroll () {
    this.element.forEach((element) => {
      element.classList.toggle('is-scrolling', window.scrollY > 130)
    })
  }
}
