<template lang="pug">
.row
  .col-12
    .error-wrapper
      ul.mb-0.pl-4(v-if="Object.keys(errors).length")
        li(v-for="error in errors") {{ error[0] }}
</template>

<script>

export default {
  props: {
    errors: {
      type: Array,
      default: undefined
    }
  }
}
</script>
