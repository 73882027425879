import Vue from 'vue'

// Plugins
import VueCarousel from 'vue-carousel'
import PrettyCheckbox from 'pretty-checkbox-vue'
import VueAgile from 'vue-agile'
import Transitions from 'vue2-transitions'
import VueMq from 'vue-mq'
import VModal from 'vue-js-modal'

// Directive
import vClickOutside from 'v-click-outside'

// Components
import ActiveItem from '@components/ActiveItem'
import ContactForm from '@components/ContactForm'
import ContactNewsletterForm from '@components/ContactNewsletterForm'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

Vue.component('ActiveItem', ActiveItem)
Vue.component('YoutubeVideo', () => import('@components/YoutubeVideo'))
Vue.component('ContactForm', ContactForm)
Vue.component('ContactNewsletterForm', ContactNewsletterForm)

Vue.use(VueCarousel)
Vue.use(vClickOutside)
Vue.use(PrettyCheckbox)
Vue.use(VueAgile)
Vue.use(Transitions)
Vue.use(VModal)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: Infinity
  }
})

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app'
  })
}, { passive: true })
