export default () => {
  const sentinel = document.querySelector('#sentinel')
  const productHero = document.querySelector('[data-is-product-hero]')

  if (productHero === null) return

  const intersectionObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      productHero.classList.toggle('hero--minified', !entry.isIntersecting)
    })
  }, {
    rootMargin: "500px"
  })

  intersectionObserver.observe(sentinel)
}
